import { assertInInjectionContext } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, CanMatchFn, UrlTree } from '@angular/router';
import { injectLazy } from 'ngxtension/inject-lazy';
import { Observable, switchMap } from 'rxjs';

import type { LazyImportLoaderFn } from 'ngxtension/inject-lazy';

export interface FeatureEnabledChecker<T> {
  checkIsEnabled: (predicate: (value: T) => boolean) => Observable<UrlTree | true>;
}

export function canNavigateOrMatch<T, Y extends FeatureEnabledChecker<T>>(
  serviceType: LazyImportLoaderFn<Y>,
  predicate: (value: T) => boolean
): Observable<UrlTree | true> {
  assertInInjectionContext(canNavigateOrMatch);
  const service$ = injectLazy(serviceType);
  return service$.pipe(switchMap((service) => service.checkIsEnabled(predicate)));
}

export function featureEnabledToMatchRouteGuard<T, Y extends FeatureEnabledChecker<T>>(
  serviceType: LazyImportLoaderFn<Y>,
  predicate: (value: T) => boolean
): CanMatchFn {
  return (): Observable<UrlTree | true> => canNavigateOrMatch(serviceType, predicate);
}

export function featureEnabledToNavigateGuard<T, Y extends FeatureEnabledChecker<T>>(
  serviceType: LazyImportLoaderFn<Y>,
  predicate: (value: T) => boolean
): CanActivateFn {
  return (): Observable<UrlTree | true> => canNavigateOrMatch(serviceType, predicate);
}

export function featureEnabledToNavigateToChildGuard<T, Y extends FeatureEnabledChecker<T>>(
  serviceType: LazyImportLoaderFn<Y>,
  predicate: (value: T) => boolean
): CanActivateChildFn {
  return (): Observable<UrlTree | true> => canNavigateOrMatch(serviceType, predicate);
}
